import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import NotificationStyles from '../../assets/jss/Components/NotificationStyles';

//redux additions
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as notificationActions from "./NotificationsActions";

//material-ui components
import {Snackbar, SnackbarContent, IconButton, Button} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

export class Notification extends React.Component {
  handleClose = (event, reason) => { //closing notification
    if (event) {
      event.preventDefault();
    }
    if (reason === "clickaway") {
      return;
    }
    this.props.actions.hideNotification();
  };

  handleUpdate = async (event) => { //update service worker
    if (event) {
      event.preventDefault();
    }
    if (navigator && navigator.serviceWorker) {
      const waitingServiceWorker = await navigator.serviceWorker.ready;

      if (waitingServiceWorker.waiting) {
        waitingServiceWorker.waiting.postMessage({type: "SKIP_WAITING"});
      }
    }
    this.props.actions.hideNotification();
  };

  render() {
    const {classes, notification, snackbar} = this.props;
    const Icon = variantIcon[notification.notificationType];

    return (
      <div>
        {!notification.update && (
          <Snackbar
            open={notification.visible}
            onClose={this.handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            autoHideDuration={3000}
            {...snackbar}
          >
            <SnackbarContent
              className={classNames(
                classes.snackBar,
                classes[notification.notificationType]
              )}
              aria-describedby="client-snackbar"
              message={
                <span
                  id="client-snackbar"
                  className={classes[`message-${notification.notificationType}`]}
                >
              <Icon className={classes.icon}/>
                  {notification.message}
            </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes[`closeIcon-${notification.notificationType}`]}
                  onClick={this.handleClose}
                >
                  <CloseIcon className={classes.icon}/>
                </IconButton>
              ]}
            />
          </Snackbar>
        )}
        {notification.update && (
          <Snackbar
            open={notification.visible}
            onClose={this.handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            {...snackbar}
          >
            <SnackbarContent
              aria-describedby="client-snackbar"
              message={
                <span
                  id="client-snackbar"
                  className={classes.updateMessage}
                >
              <Icon className={classes.icon}/>
                  {notification.message}
            </span>
              }
              action={[
                <Button color={'secondary'} size={'small'} onClick={this.handleUpdate}>
                  Update
                </Button>,
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon/>
                </IconButton>,
              ]}
            />
          </Snackbar>
        )}
      </div>
    )
  }
}

Notification.propTypes = {
  notification: PropTypes.object
};

Notification.defaultProps = {
  notification: {
    message: null,
    visible: false,
    notificationType: "info",
    update: false
  }
};

export function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(notificationActions, dispatch)};
}

//middleware
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(NotificationStyles)(Notification));