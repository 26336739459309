const WelcomeStyles = theme => ({
  main: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    display: 'block',
    margin: '0 auto',
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    borderRadius: 15
  },
  title: {
    color: '#65656d'
  },
  body: {
    color: '#888b8d'
  }
});

export default WelcomeStyles;