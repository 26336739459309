import React, {Component} from 'react';
import WelcomeStyles from "../assets/jss/Components/WelcomeStyles";
import logo from '../assets/img/Hashlog-and-Acoer-logo.svg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";

//ui reflection of file view
export class WelcomeView extends Component {

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.main}>
        <Grid container justify={'center'} spacing={4}>
          <Grid item xs={11} md={8} lg={4}>
            <img src={logo} alt="" className={classes.logo}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={'center'} alignContent={'center'} direction={'column'} spacing={3}>
              <Grid item xs={8} lg={3}>
                <Typography variant={'h6'} className={classes.title}>At a glance</Typography>
                <Typography variant={'body2'} className={classes.body}>HashLog is a data collection and visualisation
                  tool. As of today, it includes three sub-projects:
                  <ul>
                    <li>
                      <b>Health Data Explorer:</b> Platform to support and improve medicolegal death investigation;
                    </li>
                    <li>
                      <b>Knowledge Seeker:</b> Set of data analytics dashboard about clinical trials, dementia, or
                      mortality data;
                    </li>
                    <li>
                      <b>Ledger Explorer:</b> Transaction Explorer and Analytics Platform for Hedera Hashgraph, a
                      decentralized ledger technology.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <a href="https://www.acoer.com/products#hashlog" className={classes.link}>
                      <Button variant={'outlined'} color={'primary'} className={classes.button}>Learn More</Button>
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="https://www.acoer.com/contact.html" className={classes.link}>
                      <Button variant={'contained'} color={'primary'} className={classes.button}>Contact Us</Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

    );
  }
}

export default withStyles(WelcomeStyles)(WelcomeView);
