import React from 'react'
import {render} from 'react-dom'
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import registerServiceWorker from "./registerServiceWorker";

// Redux
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import history from './components/Shared/Redux/history'
import {defaultStore, persistor} from "./components/Shared/Redux/stores/defaultStore";

// Components
import Notification from './components/Notifications/Notification';
import WelcomeView from "./views/WelcomeView";
import theme from './styles/theme';

// MUI
import LinearProgressIcon from '@material-ui/core/LinearProgress';
import {MuiThemeProvider} from '@material-ui/core/styles';

render((
  <Provider store={defaultStore}>
    <PersistGate loading={<LinearProgressIcon/>} persistor={persistor}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <Notification/>
          <Switch>
            <Route exact path="/" component={WelcomeView}/>
          </Switch>
        </MuiThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
), document.getElementById('root'));

registerServiceWorker();